.App {
  text-align: center;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .clicked-fragment {
  box-shadow: inset 2px 2px goldenrod, inset -2px -2px goldenrod;
  transition: 0.3s linear all;
} */
.clickable-fragment {
  cursor: pointer;
  position: absolute;
  white-space: pre-wrap;
}
.fragment {
  display: inline-block;
  margin-bottom: 20px;
  line-height: 1rem;
  animation: fadeIn 0.3s ease;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.leftpanel {
  border-left: 1px solid black;
  width: 50%;
  text-align: left;
  padding-left: 30px;
  overflow-y: scroll;
}
.left-frag-container {
  width: 100%;
  max-width: 500px;
}
.rightpanel {
  border-right: 1px solid black;
  width: 50%;
  position: relative;
  overflow-y: scroll;
}
.modal-link {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border: none;
  background:transparent;
  text-decoration: underline;
  color:blue;
  font-size: 1rem;
  cursor: pointer;
}
.modal-inner {
  position: absolute;
  top: 25%;
  left: 30%;
  width: 40%;
  height: 50%;
}